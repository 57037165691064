<template>
    <div class="navbar-container">
        <div class="items">
            <a @click.prevent="$router.push({name:'Market'})" href=""> بازار </a>
            <a @click.prevent="$router.push({name:'Faq'})" href=""> راهنمای استفاده </a>
            <a @click.prevent="$router.push({name:'Fee'})" href=""> کارمزد ها </a>
        </div>
        <div class="avatar">
            <div class="notif-container">
            <img src="../../assets/icons/sidebarNotifs.svg" alt="" class="notification">
        </div>
        <div class="profile">
            <inline-svg :src="require('../../assets/icons/inputarrow.svg')" />
            <img class="avatar-img" src="../../assets/icons/avatar.svg" alt="">
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'DashNavbar',
    }
</script>

<style lang="scss" scoped>
.items{
    display: flex;
    flex-direction: row;
    column-gap: 40px;
}
.navbar-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 40px;
        align-items: center;
        a{
            color: var(--grey);
            font-weight: 400;
            font-size: clamp(11px,1vw,14px);
        }
}
.avatar{
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    padding-left: 10px;
    .profile{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        align-items: center;
    }
    .notif-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: var(--light-grey);
    border-radius: 4px;
    width: 36px;
    height: 36px;
}
}
@media only screen and(max-width:500px){
    .navbar-container{
        column-gap: 10px;
    }
.items{
    column-gap: 10px;
}
.avatar{
    column-gap: 10px;
    .avatar-img{
        display: none;
    }
}
}
</style>